@font-face {
  font-family: Lato;
  src: url(fonts/Lato-Regular.ttf);
}

* {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
}

.scene {
  width: 400px;
  height: auto;
  border: 1px solid #0000;
  margin: 0px auto 0px auto;
  perspective: 800px;
  padding-top: 100px;
  z-index: -999;
  position: relative;
}

.cube {
  width: 400px;
  height: 400px;
  position: relative;
  transform-style: preserve-3d;
  transform: translateZ(-100px);
  transition: transform 1s;
}

.cube.show-front {
  transform: translateZ(-100px) rotateY(0deg);
}
.cube.show-right {
  transform: translateZ(-100px) rotateY(-90deg);
}
.cube.show-back {
  transform: translateZ(-100px) rotateY(-180deg);
}
.cube.show-left {
  transform: translateZ(-100px) rotateY(90deg);
}
.cube.show-top {
  transform: translateZ(-100px) rotateX(-90deg);
}
.cube.show-bottom {
  transform: translateZ(-100px) rotateX(90deg);
}
.cube.show-emcc {
  transform: translateZ(-100px) rotateX(24deg) rotateY(0deg) rotateZ(10deg);
  animation-name: slidein;
  animation-duration: 2s;
}
@keyframes slidein {
  from {
    transform: translateZ(-100px) rotateX(90deg);
  }

  to {
    transform: translateZ(-100px) rotateX(24deg) rotateY(0deg) rotateZ(10deg);
  }
}

.cube__face {
  position: absolute;
  width: 200px;
  height: 200px;
  border: none;
  line-height: 200px;
  font-size: 40px;
  font-weight: bold;
  color: #000;
  text-align: center;
  font-family: Lato;
}

.cube__face--E {
  background: #000;
  border: none;
  clip-path: polygon(
    0% 0%,
    100% 0%,
    100% 16.7%,
    25% 16.7%,
    25% 41.7%,
    100% 41.7%,
    100% 58.3%,
    25% 58.3%,
    25% 83.3%,
    100% 83.3%,
    100% 100%,
    0% 100%
  );
  color: #000;
}
.cube__face--E::after {
  content: "";
  width: 200px;
  height: 200px;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #880015;
  clip-path: polygon(
    1.5% 1.5%,
    98.5% 1.5%,
    98.5% 15.2%,
    23.5% 15.2%,
    23.5% 43.2%,
    98.5% 43.2%,
    98.5% 56.7%,
    23.5% 56.7%,
    23.5% 84.8%,
    98.5% 84.8%,
    98.5% 98.5%,
    1.5% 98.5%
  );
}
.cube__face--M {
  background: #000;
  border: none;
  clip-path: polygon(
    0% 0%,
    0% 100%,
    25% 100%,
    50% 25%,
    75% 100%,
    100% 100%,
    100% 0%,
    83.3% 0%,
    83.3% 75%,
    58.3% 0%,
    41.7% 0%,
    16.7% 75%,
    16.7% 0%
  );
  color: #000;
}
.cube__face--M::after {
  content: "";
  width: 200px;
  height: 200px;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #fac421;
  clip-path: polygon(
    1.5% 1.5%,
    1.5% 98.5%,
    23.9% 98.5%,
    50% 21%,
    76.1% 98.5%,
    98.5% 98.5%,
    98.5% 1.5%,
    84.8% 1.5%,
    84.8% 84%,
    57.3% 1.5%,
    42.7% 1.5%,
    15.2% 84%,
    15.2% 1.5%
  );
}
.cube__face--C {
  background: #000;
  border: none;
  clip-path: polygon(
    0% 0%,
    100% 0%,
    100% 25%,
    25% 25%,
    25% 75%,
    100% 75%,
    100% 100%,
    0% 100%
  );
  color: #000;
}

.cube__face--C::after {
  content: "";
  width: 200px;
  height: 200px;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #b5e61d;
  clip-path: polygon(
    1.5% 1.5%,
    98.5% 1.5%,
    98.5% 23.5%,
    23.5% 23.5%,
    23.5% 76.5%,
    98.5% 76.5%,
    98.5% 98.5%,
    1.5% 98.5%
  );
}

.cube__face--E {
  transform: rotateX(180deg) translateZ(57.7px);
}
.cube__face--M {
  transform: rotateY(60deg) translateZ(57.7px) rotate(90deg);
}
.cube__face--C {
  transform: rotateY(300deg) translateZ(57.7px);
}

.cube__face--xeter {
  transform: translateZ(-57.7px);
  margin-left: 205px;
  padding-top: 166px;
  display: block;
  width: auto;
  line-height: 20px;
  font-size: 60px !important;
}
.cube__face--ath {
  transform: RotateY(180deg) rotateZ(270deg) rotateX(120deg) translateX(165px)
    translateY(76px) translateZ(60px);
  display: block;
  font-size: 60px !important;
}
.cube__face--lub {
  transform: RotateY(300deg) rotateZ(0deg) rotateX(0deg) translateX(-70px)
    translateY(210px) translateZ(60px);
  display: block;
  font-size: 60px !important;
  padding-left: 80px;
  padding-right: 80px;
  line-height: 50px;
  text-align: left;
  word-break: break-all;
}
.cube__face--M {
  transform: rotateY(60deg) translateZ(57.7px) rotate(90deg);
}
.cube__face--C {
  transform: rotateY(300deg) translateZ(57.7px);
}
